import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import { firstValueFrom } from "rxjs";
import { APP_CONFIG } from "../../environments/environment";
import { ApiService } from "./api.service";
import { ApplicationService } from "./application.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class GraphQLService {
  private app: ApplicationService;
  private msal: MsalService;
  private api: ApiService;
  private http: HttpClient;
  private snack: ToastService;
  private router: Router;

  public constructor(app: ApplicationService, msal: MsalService, api: ApiService, http: HttpClient, snack: ToastService, router: Router) {
    this.app = app;
    this.msal = msal;
    this.api = api;
    this.http = http;
    this.snack = snack;
    this.router = router;

    // this.api.getApiList().put("gql", new Api(http, APP_CONFIG.API_URL));
  }

  /**
   * Parses an object to fit in the mutation
   * @param object
   * @returns
   */
  public parseObject(object: unknown): unknown {
    return JSON.stringify(object).replace(/"([^"]+)":/g, "$1:");
  }

  public async query(query: string): Promise<unknown> {
    console.log("[Query] Running query", {
      query: query,
    });

    try {
      const res = await firstValueFrom(
        this.getApi()
          .get("gql")
          .post(
            "query",
            {
              query: query,
            },
            {
              "x-hasura-role": Object.keys(this.getApp().getSession().getRoles())[0],
              [APP_CONFIG.JWT ? "Authorization" : "undefined"]: `Bearer ${APP_CONFIG.JWT}`,
            }
          )
      );

      if (res["errors"]) {
        for (const error of res["errors"]) {
          throw error.message;
        }
      }

      return res;
    } catch (error) {
      console.log("[Error] Unable to execute query", query);
      console.log(error);
      if (error.toString().includes("401 Unauthorized")) {
        const selectedRole = localStorage.getItem("selectedRole");

        localStorage.clear();
        if (selectedRole) localStorage.setItem("selectedRole", selectedRole);
        this.getSnack().open("U moet opnieuw inloggen", "a");
        location.reload();
      } else {
        throw error;
      }
    }
  }

  private async checkToken(): Promise<AuthenticationResult> {
    return await firstValueFrom(
      this.getMsal().acquireTokenSilent({
        scopes: [],
        forceRefresh: false,
      })
    );
  }

  /*
   * Getters & Setters
   */

  public getApp(): ApplicationService {
    return this.app;
  }

  public setApp(app: ApplicationService): void {
    this.app = app;
  }

  public getMsal(): MsalService {
    return this.msal;
  }

  public setMsal(msal: MsalService): void {
    this.msal = msal;
  }

  public getApi(): ApiService {
    return this.api;
  }

  public setApi(api: ApiService): void {
    this.api = api;
  }

  public getHttp(): HttpClient {
    return this.http;
  }

  public setHttp(http: HttpClient): void {
    this.http = http;
  }

  public getSnack(): ToastService {
    return this.snack;
  }

  public setSnack(snack: ToastService): void {
    this.snack = snack;
  }

  public getRouter(): Router {
    return this.router;
  }

  public setRouter(router: Router): void {
    this.router = router;
  }
}
