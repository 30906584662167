export const APP_CONFIG = {
  APPLICATION_NAME: "Dashboard Vitaliteitsknip Heerlen",
  production: false,
  environment: "DEV",
  TEAMS_API: "https://www.google.com/",
  API: "https://api.dev.iota.vitaliteitsknip.nl/",
  //API: "http://localhost:3333/",
  HASURA_ADMIN_KEY: null,
  TIMER_TICK_RATE: 100,
  JWT: null,
  MSAL: {
    CLIENT_ID: "06b1baf1-8d3c-4bc2-9e30-4830cb09cd28",
    AUTHORITY: "https://vitknipdev.b2clogin.com/vitknipdev.onmicrosoft.com/B2C_1_TEST",
    KNOWN_AUTHORITY: "https://vitknipdev.b2clogin.com/",
  },
};
