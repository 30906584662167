import { Column } from "./column";

export class Row {
  private id: string;
  private index: number;
  private data: Map<Column, unknown>;
  private active: boolean;

  public constructor(id: string, data: Map<Column, unknown>, active = true) {
    this.id = id;
    this.index = 0;
    this.data = data;
    this.active = active;
  }

  public getDataByColumn(column: Column): unknown {
    return this.getData().get(column);
  }

  public getDataByColumnString(string: string): unknown {
    return this.getData().get(
      Array.from(this.getData()
        .keys())
        .find((column) => {
          return column.getLabel() == string;
        })
    );
  }

  public getDataAsObject(): unknown {
    const obj = {};
    for (const column of Array.from(this.getData().keys())) obj[column.getLabel()] = this.getData().get(column);
    return obj;
  }

  /*
   * Getters & Setters
   */

  public getId(): string {
    return this.id;
  }

  public setId(id: string): void {
    this.id = id;
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): void {
    this.index = index;
  }

  public getData(): Map<Column, unknown> {
    return this.data;
  }

  public setData(data: Map<Column, unknown>): void {
    this.data = data;
  }

  public isActive(): boolean {
    return this.active;
  }

  public setActive(active: boolean): void {
    this.active = active;
  }
}
