import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Column } from "./column";
import { Row } from "./row";
import { Table, TableTemplate } from "./table";
import { MatPaginator } from "@angular/material/paginator";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.less"],
})
export class TableComponent extends Table implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input("id")
  public inputId: string;

  @Input("rows")
  public inputRows: unknown[];

  @Input("columns")
  public inputColumns: string[];

  @Input("templates")
  public inputTemplates: TableTemplate[];

  @Input("pagination")
  public pagination: boolean;

  @Input("search")
  public search: boolean;

  @Output()
  public rowClick: EventEmitter<Row>;

  @Output()
  public columnClick: EventEmitter<Column>;

  private height: number;
  private size: number;

  private filterTimeout: NodeJS.Timeout;

  public constructor() {
    super();

    this.inputId = null;
    this.inputRows = [];
    this.inputColumns = [];
    this.inputTemplates = [];

    this.pagination = false;
    this.search = false;

    this.rowClick = new EventEmitter<Row>();
    this.columnClick = new EventEmitter<Column>();

    this.height = 400;
    this.size = 10;
  }

  public ngOnInit(): void {
    const rows = this.getInputRows().length + 1;
    const size = this.getSize();

    this.initialize(this.getInputId(), this.getInputRows(), this.getInputTemplates());
    this.setHeight(53 * (rows > size ? size : rows));
  }

  /**
   * Fired when giving input in search field
   * @param event
   */
  public filterRows(event: Event): void {
    clearTimeout(this.getFilterTimeout());
    this.setFilterTimeout(
      setTimeout(() => {
        const value = (event.target as HTMLInputElement).value;
        for (const index of Array.from(this.getRows().keys())) {
          const row = this.getRows().get(index);
          row.setActive(false);

          for (const data of Array.from(row.getData().values())) {
            if (data.toString().toLowerCase().includes(value.toLowerCase()) || value.length == 0) {
              row.setActive(true);
              break;
            }
          }
        }

        // this.update();
      }, 0)
    );
  }

  /**
   * Fired when clicking on a row
   * @param row
   */
  public clickRow(row: Row): void {
    this.getRowClick().emit(row);
  }

  /**
   * Fired when clicking on a column
   * @param column
   */
  public clickColumn(column: Column): void {
    this.getColumnClick().emit(column);
  }

  /*
   * Getters & Setters
   */

  public getInputId(): string {
    return this.inputId;
  }

  public setInputId(inputId: string): void {
    this.inputId = inputId;
  }

  public getInputRows(): unknown[] {
    return this.inputRows;
  }

  public setInputRows(inputRows: unknown[]): void {
    this.inputRows = inputRows;
  }

  public getInputColumns(): string[] {
    return this.inputColumns;
  }

  public setInputColumns(inputColumns: string[]): void {
    this.inputColumns = inputColumns;
  }

  public getRowClick(): EventEmitter<Row> {
    return this.rowClick;
  }

  public setRowClick(rowClick: EventEmitter<Row>): void {
    this.rowClick = rowClick;
  }

  public getColumnClick(): EventEmitter<Column> {
    return this.columnClick;
  }

  public setColumnClick(columnClick: EventEmitter<Column>): void {
    this.columnClick = columnClick;
  }

  public getHeight(): number {
    return this.height;
  }

  public setHeight(height: number): void {
    this.height = height;
  }

  public getSize(): number {
    return this.size;
  }

  public setSize(size: number): void {
    this.size = size;
  }

  public isPagination(): boolean {
    return this.pagination;
  }

  public setPagination(pagination: boolean): void {
    this.pagination = pagination;
  }

  public isSearch(): boolean {
    return this.search;
  }

  public setSearch(search: boolean): void {
    this.search = search;
  }

  public getInputTemplates(): TableTemplate[] {
    return this.inputTemplates;
  }

  public setInputTemplates(inputTemplates: TableTemplate[]): void {
    this.inputTemplates = inputTemplates;
  }

  public getFilterTimeout(): NodeJS.Timeout {
    return this.filterTimeout;
  }

  public setFilterTimeout(filterTimeout: NodeJS.Timeout): void {
    this.filterTimeout = filterTimeout;
  }
}
