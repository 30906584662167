import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";
import { SessionRole } from "./classes/sessionRole";
import { RoleGuard } from "./guards/role.guard";
import { DashboardComponent } from "./screens/dashboard/dashboard.component";
import { EmployeesComponent } from "./screens/employees/employees.component";
import { FinancialComponent } from "./screens/financial/financial.component";
import { RetailersComponent } from "./screens/retailers/retailers.component";
import { UsersComponent } from "./screens/users/users.component";

const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "dashboard",
  },
  {
    path: "dashboard",
    component: DashboardComponent,
  },
  // {
  //   path: "employees",
  //   component: EmployeesComponent,
  //   data: { roles: [SessionRole.HR] },
  // },
  {
    path: "employees/:state",
    component: EmployeesComponent,
    canActivate: [RoleGuard, MsalGuard],
    data: { roles: [SessionRole.HR] },
  },
  {
    path: "users/:state",
    component: UsersComponent,
    canActivate: [RoleGuard, MsalGuard],
    data: { roles: [SessionRole.ADMIN] },
  },
  {
    path: "retailers",
    component: RetailersComponent,
    canActivate: [RoleGuard, MsalGuard],
    data: { roles: [SessionRole.RETAILER] },
  },
  {
    path: "financial",
    component: FinancialComponent,
    canActivate: [RoleGuard, MsalGuard],
    data: { roles: [SessionRole.FINANCIAL] },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? "enabled" : "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
