<div class="tableContainer" [ngClass]="{ loading: isLoading() }">
  <ng-container *ngIf="isSearch()" [ngTemplateOutlet]="search" [ngTemplateOutletContext]="{ data: [] }"></ng-container>

  <ng-container *ngIf="isLoading(); else table">
    <mat-spinner></mat-spinner>
  </ng-container>

  <ng-container *ngIf="isPagination()" [ngTemplateOutlet]="pagination" [ngTemplateOutletContext]="{ data: [] }">
  </ng-container>
</div>

<ng-template #table>
  <cdk-virtual-scroll-viewport [itemSize]="getSize()" class="wrapper" [style.height.px]="getHeight()">
    <table mat-table *ngIf="!isLoading()" [dataSource]="getDataSource().data">
      <ng-container *ngFor="let column of getColumns().values()" [matColumnDef]="column.getLabel()">
        <ng-container *ngIf="column.isActive()">
          <th mat-header-cell *matHeaderCellDef [attr.title]="column.getLabel()" (click)="clickColumn(column)">
            <span>{{ "TABLES." + getId() + "." + column.getLabel() | translate }}</span>
          </th>

          <td mat-cell *matCellDef="let element; let ind = index">
            <ng-container *ngIf="getRow(ind)">
              <ng-container *ngIf="!getTemplate(column); else template">
                {{ getRow(ind).getDataByColumn(column) }}
              </ng-container>
            </ng-container>

            <ng-template #template>
              <ng-container *ngIf="getRow(ind)" [ngTemplateOutlet]="getTemplate(column)"
                [ngTemplateOutletContext]="{ i: { data: getRow(ind).getDataByColumn(column), index: ind } }">
              </ng-container>
            </ng-template>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="getColumnDef(); sticky: true" class="visible"></tr>
      <tr mat-row *matRowDef="let row; columns: getColumnDef(); let index = index"
        [ngClass]="{ visible: getRow(index) ? getRow(index).isActive() : false }"
        (click)="clickRow(getRows().values()[index])"></tr>

      <!-- <tr
      mat-row
      *matRowDef="let row; columns: getColumnDef(); let index = index"
      [ngClass]="{ visible: getRow(index) ? getRow(index).isActive() : false }"
      (click)="clickRow(getRows().values()[index])"
    ></tr> -->
    </table>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #pagination>
  <mat-paginator howFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
</ng-template>

<ng-template #search>
  <mat-form-field appearance="outline">
    <mat-label>Filter</mat-label>
    <input matInput placeholder="Filter..." (keyup)="filterRows($event)" />
  </mat-form-field>
</ng-template>