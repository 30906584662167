<mat-progress-bar mode="indeterminate" *ngIf="getApp().isLoading()"></mat-progress-bar>
<app-toaster style="z-index: 9999"></app-toaster>

<div class="container">
  <div class="row">
    <div class="col-md">
      <app-header></app-header>
    </div>
  </div>

  <!-- Adding standard space -->
  <div class="my-2 py-2"></div>

  <!--  -->
  <div class="row">
    <div class="col-md">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- Adding standard space -->
<div class="my-5 py-5"></div>
