import { Component } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { firstValueFrom } from "rxjs";
import { ApiService } from "../../services/api.service";
import { ApplicationService } from "../../services/application.service";
import { MicrosoftService } from "../../services/microsoft.service";
import { SessionService } from "../../services/session.service";
import { ToastService } from "../../services/toast.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.less"],
})
export class DashboardComponent {
  private app: ApplicationService;
  private session: SessionService;
  private api: ApiService;
  private snack: ToastService;
  private microsoft: MicrosoftService;

  public constructor(app: ApplicationService, session: SessionService, api: ApiService, snack: ToastService, microsoft: MicrosoftService, public msal: MsalService) {
    this.app = app;
    this.session = session;
    this.api = api;
    this.snack = snack;
    this.microsoft = microsoft;
  }

  public async pingApi(): Promise<void> {
    const api = this.getApi().get("default");

    const res = await firstValueFrom(api.post("ping"));

    if (res["data"].success) {
      alert(`Message from API -> ${res["data"].success.message}`);
    } else {
      alert(`Error from API -> ${res["data"].error.message}`);
    }
  }

  public openSnack(): void {
    this.snack.open(
      "Voorbeeld snackbar!",
      "Hi dit is een voorbeeld",
      [
        {
          label: "OK",
          action: () => {
            console.log("OK");
            alert("OK");
          },
        },
      ],
      1000000
    );
  }

  public async createAccount(email: string, name: string): Promise<void> {
    await this.getMicrosoft().createUser(email, name);
  }

  /*
   * Getters & Setters
   */

  public getApp(): ApplicationService {
    return this.app;
  }

  public setApp(app: ApplicationService): void {
    this.app = app;
  }

  public getSession(): SessionService {
    return this.session;
  }

  public setSession(session: SessionService): void {
    this.session = session;
  }

  public getApi(): ApiService {
    return this.api;
  }

  public setApi(api: ApiService): void {
    this.api = api;
  }

  public getSnack(): ToastService {
    return this.snack;
  }

  public setSnack(snack: ToastService): void {
    this.snack = snack;
  }

  public getMicrosoft(): MicrosoftService {
    return this.microsoft;
  }

  public setMicrosoft(microsoft: MicrosoftService): void {
    this.microsoft = microsoft;
  }
}
