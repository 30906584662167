export class Node {
  private name: string;

  private healthy: boolean;

  private version: string;
  private networkId: string;
  private bech32HRP: string;

  private minPoWScore: number;
  private messagesPerSecond: number;
  private referencedMessagesPerSecond: number;
  private referencedRate: number;
  private latestMilestoneTimestamp: number;
  private latestMilestoneIndex: number;
  private confirmedMilestoneIndex: number;
  private pruningIndex: number;

  private features: string[];

  public constructor(name: string) {
    this.name = name;

    this.healthy = false;

    this.version = null;
    this.networkId = null;
    this.bech32HRP = null;

    this.minPoWScore = -1;
    this.messagesPerSecond = -1;
    this.referencedMessagesPerSecond = -1;
    this.referencedRate = -1;
    this.latestMilestoneTimestamp = -1;
    this.latestMilestoneIndex = -1;
    this.confirmedMilestoneIndex = -1;
    this.pruningIndex = -1;

    this.features = [];
  }

  public update(properties: Record<string, unknown>) {
    for (const key of Object.keys(properties)) {
      if (this[key] != undefined) this[key] = properties[key];
    }
  }

  /*
   * Getters & Setters
   */

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getVersion(): string {
    return this.version;
  }

  public setVersion(version: string): void {
    this.version = version;
  }

  public isHealthy(): boolean {
    return this.healthy;
  }

  public setHealthy(healthy: boolean): void {
    this.healthy = healthy;
  }

  public getNetworkId(): string {
    return this.networkId;
  }

  public setNetworkId(networkId: string): void {
    this.networkId = networkId;
  }

  public getBech32HRP(): string {
    return this.bech32HRP;
  }

  public setBech32HRP(bech32HRP: string): void {
    this.bech32HRP = bech32HRP;
  }

  public getMinPoWScore(): number {
    return this.minPoWScore;
  }

  public setMinPoWScore(minPoWScore: number): void {
    this.minPoWScore = minPoWScore;
  }

  public getMessagesPerSecond(): number {
    return this.messagesPerSecond;
  }

  public setMessagesPerSecond(messagesPerSecond: number): void {
    this.messagesPerSecond = messagesPerSecond;
  }

  public getReferencedMessagesPerSecond(): number {
    return this.referencedMessagesPerSecond;
  }

  public setReferencedMessagesPerSecond(referencedMessagesPerSecond: number): void {
    this.referencedMessagesPerSecond = referencedMessagesPerSecond;
  }

  public getReferencedRate(): number {
    return this.referencedRate;
  }

  public setReferencedRate(referencedRate: number): void {
    this.referencedRate = referencedRate;
  }

  public getLatestMilestoneTimestamp(): number {
    return this.latestMilestoneTimestamp;
  }

  public setLatestMilestoneTimestamp(latestMilestoneTimestamp: number): void {
    this.latestMilestoneTimestamp = latestMilestoneTimestamp;
  }

  public getLatestMilestoneIndex(): number {
    return this.latestMilestoneIndex;
  }

  public setLatestMilestoneIndex(latestMilestoneIndex: number): void {
    this.latestMilestoneIndex = latestMilestoneIndex;
  }

  public getConfirmedMilestoneIndex(): number {
    return this.confirmedMilestoneIndex;
  }

  public setConfirmedMilestoneIndex(confirmedMilestoneIndex: number): void {
    this.confirmedMilestoneIndex = confirmedMilestoneIndex;
  }

  public getPruningIndex(): number {
    return this.pruningIndex;
  }

  public setPruningIndex(pruningIndex: number): void {
    this.pruningIndex = pruningIndex;
  }

  public getFeatures(): string[] {
    return this.features;
  }

  public setFeatures(features: string[]): void {
    this.features = features;
  }
}
