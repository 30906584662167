import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ApiService {
  private httpClient: HttpClient;
  private apiList: Map<string, Api>;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.apiList = new Map<string, Api>();
  }

  /**
   * Returns the corresponding API
   * @param name name of the api: testApi, apimApi of teamsApi
   * @returns
   */
  public get(name: string): Api {
    const api = this.getApiList().get(name);
    if (!api) throw new Error(`Undefined api: ${name}`);
    return api;
  }

  public add(name: string, url: string): Api {
    const list = this.getApiList();
    const http = this.getHttpClient();
    const api = new Api(http, url);
    list.set(name, api);
    return api;
  }

  /*
   * Getters & Setters
   */

  public getHttpClient(): HttpClient {
    return this.httpClient;
  }

  public setHttpClient(httpClient: HttpClient): void {
    this.httpClient = httpClient;
  }

  public getApiList(): Map<string, Api> {
    return this.apiList;
  }

  public setApiList(apiList: Map<string, Api>): void {
    this.apiList = apiList;
  }
}
interface Header {
  [key: string]: string;
}
export class Api {
  private httpClient: HttpClient;
  private api: string;

  private defaultHeaders: Header;

  public constructor(httpClient: HttpClient, api: string, defaultHeaders: Header = {}) {
    this.httpClient = httpClient;
    this.api = api;
    this.defaultHeaders = defaultHeaders;
  }

  public get(endpoint: string, data: unknown = {}, customHeaders: Header = { "x-ms-max-item-count": "500" }): Observable<unknown> {
    const headers: HttpHeaders = new HttpHeaders(Object.assign(this.getDefaultHeaders(), customHeaders));
    return this.getHttpClient().request("GET", `${this.getApi()}${endpoint}${this.objectToParams(data)}`, {
      headers: headers,
    });
  }

  public post(endpoint: string, data?: unknown, customHeaders: Header = {}, options: Header = { "x-ms-max-item-count": "500" }): Observable<unknown> {
    const headers: HttpHeaders = new HttpHeaders(Object.assign(this.getDefaultHeaders(), customHeaders));
    const mergedOptions = Object.assign({ headers: headers }, options);

    return this.getHttpClient()
      .post(`${this.getApi()}${endpoint}`, data, mergedOptions)
      .pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
  }

  public patch(endpoint: string, data: unknown, customHeaders: Header = {}, options: Header = { "x-ms-max-item-count": "500" }): Observable<unknown> {
    const headers: HttpHeaders = new HttpHeaders(Object.assign(this.getDefaultHeaders(), customHeaders));
    const mergedOptions = Object.assign({ headers: headers }, options);

    return this.getHttpClient()
      .patch(`${this.getApi()}${endpoint}`, data, mergedOptions)
      .pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
  }

  public put(endpoint: string, data: unknown, customHeaders: Header = { "x-ms-max-item-count": "500" }, options: Header = {}): Observable<unknown> {
    const headers: HttpHeaders = new HttpHeaders(Object.assign(this.getDefaultHeaders(), customHeaders));
    const mergedOptions = Object.assign({ headers: headers }, options);

    return this.getHttpClient()
      .put(`${this.getApi()}${endpoint}`, data, mergedOptions)
      .pipe(catchError((err: HttpErrorResponse) => this.errorHandler(err)));
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error.message);
  }

  private objectToParams(obj: unknown): string {
    const params: string[] = [];
    for (const i of Object.keys(obj)) params.push(new HttpParams().set(i, obj[i]).toString());
    return params.length > 0 ? "?" + params.join("&") : "";
  }

  /*
   * Getters & Setters
   */

  public getApi(): string {
    return this.api;
  }

  public setApi(api: string): void {
    this.api = api;
  }

  public getHttpClient(): HttpClient {
    return this.httpClient;
  }

  public setHttpClient(httpClient: HttpClient): void {
    this.httpClient = httpClient;
  }

  public getDefaultHeaders(): Header {
    return this.defaultHeaders;
  }

  public setDefaultHeaders(defaultHeaders: Header): void {
    this.defaultHeaders = defaultHeaders;
  }
}
