export class Column {
  private id: string;
  private index: number;
  private label: string;
  private active: boolean;

  public constructor(id: string, label: string, index = 1, active = true) {
    this.id = id;
    this.index = index;
    this.label = label;
    this.active = active;
  }

  /*
   * Getters & Setters
   */

  public getId(): string {
    return this.id;
  }

  public setId(id: string): void {
    this.id = id;
  }

  public getIndex(): number {
    return this.index;
  }

  public setIndex(index: number): void {
    this.index = index;
  }

  public getLabel(): string {
    return this.label;
  }

  public setLabel(label: string): void {
    this.label = label;
  }

  public isActive(): boolean {
    return this.active;
  }

  public setActive(active: boolean): void {
    this.active = active;
  }
}
