import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { SessionService } from "../services/session.service";
import { ToastService } from "../services/toast.service";

@Injectable({
  providedIn: "root",
})
export class RoleGuard implements CanActivate {
  private session: SessionService;
  private router: Router;
  private toast: ToastService;

  public constructor(session: SessionService, router: Router, toast: ToastService) {
    this.session = session;
    this.router = router;
    this.toast = toast;
  }

  public canActivate(route: ActivatedRouteSnapshot) {
    const session = this.getSession();
    const router = this.getRouter();
    const roles = session.getRoles();
    const required = route.data.roles;

    console.log("Requesting access => ", {
      required: required,
      roles: this.getSession().getRoles(),
    });

    for (const role of required) {
      if (!roles.includes(role)) {
        this.getToast().open("✔", "You have insufficient permissions to access this page.");
        router.navigate(["dashboard"]);
        return false;
      }
    }

    return true;
  }

  /*
   * Getters & Setters
   */

  public getSession(): SessionService {
    return this.session;
  }

  public setSession(session: SessionService): void {
    this.session = session;
  }

  public getRouter(): Router {
    return this.router;
  }

  public setRouter(router: Router): void {
    this.router = router;
  }

  public getToast(): ToastService {
    return this.toast;
  }

  public setToast(toast: ToastService): void {
    this.toast = toast;
  }
}
