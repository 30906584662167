import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { APP_CONFIG } from "../../environments/environment";
import { ElectronService } from "./electron.service";
import { TeamsService } from "./teams.service";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Timer, TimerService } from "./timer.service";
import { SessionService } from "./session.service";

@Injectable()
export class ApplicationService {
  private name: string;
  private title: string;
  private host: string;
  private subscriptions: Map<string, Subscription[]>;

  private titleService: Title;
  private electron: ElectronService;
  private teams: TeamsService;
  private translate: TranslateService;
  private router: Router;
  private timer: TimerService;
  private session: SessionService;

  private debug: boolean;

  private initialized: boolean;
  private loading: boolean;

  public constructor(
    titleService: Title,
    electron: ElectronService,
    teams: TeamsService,
    translate: TranslateService,
    router: Router,
    timer: TimerService,
    session: SessionService
  ) {
    this.titleService = titleService;
    this.electron = electron;
    this.teams = teams;
    this.translate = translate;
    this.router = router;
    this.timer = timer;
    this.session = session;

    this.name = APP_CONFIG.APPLICATION_NAME;
    this.title = "not-set";

    this.subscriptions = new Map<string, Subscription[]>();
    this.initialized = false;
    this.loading = true;

    this.debug = !APP_CONFIG.production;
  }

  /**
   * Starts the initialization of the application
   */
  public async initialize(): Promise<void> {
    this.getTitleService().setTitle(this.getName());

    this.getTranslate().setDefaultLang("nl");
    this.getTranslate().use("nl");

    this.getSession().initialize();

    console.log("Starting application..", {
      config: APP_CONFIG,
      language: this.getTranslate().getDefaultLang(),
      session: {
        browser: this.getSession().getBrowser(),
      },
    });

    // APP_CONFIG.JWT = JSON.parse(localStorage.getItem(Object.keys(localStorage).find((key) => key.includes("idtoken"))))?.secret;
    await this.initializeHost();
  }

  /**
   * Sets the host
   */
  private async initializeHost(): Promise<void> {
    try {
      await this.getTeams().login((success: boolean) => {
        console.log("Successful teams connection.", {
          success: success,
        });
      });
      this.setHost("Teams");
    } catch (error) {
      this.setHost(this.getElectron().isElectron() ? "Electron" : "Web");
    }
  }

  /**
   * Updates the subtitle of the application
   * @param title The subtitle
   */
  public updateTitle(title: string): void {
    this.getTitleService().setTitle(`${this.getName()} - ${title}`);
  }

  /**
   * Adds a subscription to be collected for auto-unsubscribe
   * @param key subscription key id
   * @param subscription one or multiple Subscriptions
   */
  public subscribe(key: string, subscription: Subscription | Subscription[]): void {
    try {
      const subscriptions: Subscription[] = this.getSubscriptions().get(key);
      const _subscription = Array.isArray(subscription) ? subscription : [subscription];
      this.getSubscriptions().set(key, [...(subscriptions || []), ..._subscription]);
    } catch (error) {
      console.log(`[ERROR] Unable to subscribe with key -> ${key}`);
      throw new Error(error);
    }
  }

  /**
   * Removes all subscriptions depending on key
   * @param key subscription key id
   */
  public unsubscribe(key: string): void {
    if (this.isDebug()) console.log(`[Component] Destroying id -> ${key}`);
    try {
      const subscriptions: Subscription[] = this.getSubscriptions().get(key) || [];
      for (const subscription of subscriptions) subscription.unsubscribe();
    } catch (error) {
      console.log(`[ERROR] Unable to unsubscribe with key -> ${key}`);
      throw new Error(error);
    }
  }

  /**
   * Enables the page loader for a certain amount of ticks
   * @param ticks
   */
  public loader(ticks: number): void {
    this.setLoading(true);
    this.getTimer()
      .setTimer(
        "loading",
        new Timer(() => {
          this.setLoading(false);
        }, ticks)
      )
      .runOnce();
  }

  /*
   * Getters & Setters
   */

  public isLoading(): boolean {
    return this.loading;
  }

  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  public getTitle(): string {
    return this.title;
  }

  public setTitle(title: string): void {
    this.title = title;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getTitleService(): Title {
    return this.titleService;
  }

  public setTitleService(titleService: Title): void {
    this.titleService = titleService;
  }

  public getElectron(): ElectronService {
    return this.electron;
  }

  public setElectron(electron: ElectronService): void {
    this.electron = electron;
  }

  public getSession(): SessionService {
    return this.session;
  }

  public setSession(session: SessionService): void {
    this.session = session;
  }

  public getTeams(): TeamsService {
    return this.teams;
  }

  public setTeams(teams: TeamsService): void {
    this.teams = teams;
  }

  public getTranslate(): TranslateService {
    return this.translate;
  }

  public setTranslate(translate: TranslateService): void {
    this.translate = translate;
  }

  public getHost(): string {
    return this.host;
  }

  public setHost(host: string): void {
    this.host = host;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  public setInitialized(initialized: boolean): void {
    this.initialized = initialized;
  }

  public getSubscriptions(): Map<string, Subscription[]> {
    return this.subscriptions;
  }

  public setSubscriptions(subscriptions: Map<string, Subscription[]>): void {
    this.subscriptions = subscriptions;
  }

  public getRouter(): Router {
    return this.router;
  }

  public setRouter(router: Router): void {
    this.router = router;
  }

  public getTimer(): TimerService {
    return this.timer;
  }

  public setTimer(timer: TimerService): void {
    this.timer = timer;
  }

  public isDebug(): boolean {
    return this.debug;
  }

  public setDebug(debug: boolean): void {
    this.debug = debug;
  }
}
