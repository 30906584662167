<div class="container-fluid">
  <div id="headerContainer">
    <div id="logoContainer">
      <img routerLink="/dashboard" id="logo" src="../../../assets/images/logo.png" />
      <label>versie {{getVersion()}}</label>
    </div>

    <div id="menuContainer" class="d-md-flex">
      <!-- d-none  -->
      <ul>
        <li *ngIf="getSession().hasRole(3)">
          <span routerLink="/employees/existing">{{ "COMPONENTS.HEADER.employees" | translate }} {{ "COMPONENTS.HEADER.employees_existing" | translate }}</span>
        </li>

        <li *ngIf="getSession().hasRole(3)">
          <span routerLink="/employees/add">{{ "COMPONENTS.HEADER.employees" | translate }} {{ "COMPONENTS.HEADER.employees_new" | translate }} </span>
        </li>

        <li *ngIf="getSession().hasRole(5)">
          <span routerLink="/retailers">{{ "COMPONENTS.HEADER.retailers" | translate }}</span>
        </li>

        <li *ngIf="getSession().hasRole(4)">
          <span routerLink="/financial">{{ "COMPONENTS.HEADER.financial" | translate }}</span>
        </li>
      </ul>
    </div>

    <div id="nodeContainer">
      <ul>
        <li *ngFor="let node of getNode().getNodes().values(); let index = index">
          <span class="node offline" [ngClass]="{ online: node.isHealthy() }"></span>
          <!-- <span class="name">{{ node.getName() }}</span> -->
          <span class="name">NODE-{{ index + 1 }}</span>
        </li>
      </ul>
    </div>

    <div id="userContainer" class="text-center" *ngIf="getSession().isLoggedIn()">
      Welkom, <br />
      {{ getSession().getName() }}
    </div>

    <div id="signContainer">
      <ng-container *ngIf="getSession().isLoggedIn(); else login">
        <button mat-flat-button (click)="getSession().logout()">
          <mat-icon>logout</mat-icon>
          <!--TODO to be translated -->
          <span>Uitloggen</span>
        </button>
      </ng-container>

      <ng-template #login>
        <button mat-flat-button (click)="getSession().login()">
          <mat-icon>login</mat-icon>
          <!--TODO to be translated -->
          <span>Inloggen</span>
        </button>
      </ng-template>
    </div>
  </div>
</div>
