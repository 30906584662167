import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { EventTypes } from "../components/snacks/EventTypes";
import { ToastEvent } from "../components/snacks/ToastEvent";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  toastEvents: Observable<ToastEvent>;
  private _toastEvents = new Subject<ToastEvent>();

  constructor() {
    this.toastEvents = this._toastEvents.asObservable();
  }

  /**
   * Show success toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  open(title: string, message: string, buttons: { label: string; action: () => void }[] = [], duration = 3000) {
    this._toastEvents.next({
      duration,
      buttons,
      message,
      title,
      type: EventTypes.Success,
    });
  }

  /**
   * Show error toast notification.
   * @param title Toast title
   * @param message Toast message
   */
  error(title: string, message: string, buttons: { label: string; action: () => void }[] = [], duration = 3000) {
    this._toastEvents.next({
      duration,
      message,
      buttons,
      title,
      type: EventTypes.Error,
    });
  }
}
