import { Component, Inject, TemplateRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogService } from "./dialog.service";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.less"],
})
export class DialogComponent {
  private dialogService: DialogService;
  private templateRef: TemplateRef<unknown>;
  private data: unknown;

  public dialogRef: MatDialogRef<unknown>;

  public constructor(@Inject(MAT_DIALOG_DATA) data, dialogRef: MatDialogRef<unknown>, dialogService: DialogService) {
    this.dialogService = dialogService;
    this.templateRef = data.template;
    this.data = data.data;
    this.dialogRef = dialogRef;
  }

  public closeDialog(): void {
    this.getDialogService().close();
  }

  /*
   * Getters & Setters
   */

  public getData(): unknown {
    return this.data;
  }

  public setData(data: unknown): void {
    this.data = data;
  }

  public getDialogService(): DialogService {
    return this.dialogService;
  }
  public setDialogService(dialogService: DialogService): void {
    this.dialogService = dialogService;
  }
  public getTemplateRef(): TemplateRef<unknown> {
    return this.templateRef;
  }
  public setTemplateRef(templateRef: TemplateRef<unknown>): void {
    this.templateRef = templateRef;
  }
  public getDialogRef(): MatDialogRef<unknown> {
    return this.dialogRef;
  }
  public setDialogRef(dialogRef: MatDialogRef<unknown>): void {
    this.dialogRef = dialogRef;
  }
}
