import { Injectable } from "@angular/core";
import * as microsoftTeams from "@microsoft/teams-js";
import { HttpClient } from "@angular/common/http";
import { APP_CONFIG } from "../../environments/environment";
import { Api, ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class TeamsService {
  private http: ApiService;
  private httpClient: HttpClient;

  private context: microsoftTeams.Context;

  public constructor(http: ApiService, httpClient: HttpClient) {
    this.http = http;
    this.httpClient = httpClient;
    this.context = null;
    microsoftTeams.initialize();
  }

  public login(success: { (success: boolean): void; (arg0: boolean): void }): Promise<void> {
    return new Promise((resolve: (value: void | PromiseLike<void>) => void, reject: (reason: string) => void) => {
      microsoftTeams.getContext((context: microsoftTeams.Context) => {
        this.setContext(context || null);
        if (context) {
          microsoftTeams.authentication.getAuthToken({
            successCallback: (result: string) => {
              this.getHttp()
                .getApiList()
                .set(
                  "teamsApi",
                  new Api(this.getHttpClient(), APP_CONFIG.TEAMS_API, {
                    "Content-Type": "application/json",
                    tid: "7da2d80e-57f8-4d1b-aef1-8e87d17ad58d",
                    token: result,
                    use: APP_CONFIG.production ? "live" : "prod",
                  })
                );
              success(true);
            },
            failureCallback: (error: string) => {
              console.error("Failed to get TeamsToken => ", error);
              success(false);
            },
          });
          resolve();
        } else {
          reject("Not in teams");
        }
      });
      if (window.name != "embedded-page-container" && window.name != "extension-tab-frame") reject("Not in teams");
    });
  }

  public returnRoleAuth(allowedRoles: number[]): boolean {
    if (allowedRoles == null || allowedRoles.length === 0) {
      return true;
    }
    const activeRole = JSON.parse(localStorage.getItem("activeRole") || "{}");
    return allowedRoles.includes(activeRole);
  }

  public hasAccess(): boolean {
    return true;
  }

  /*
   * Getters & Setters
   */
  public getHttp(): ApiService {
    return this.http;
  }

  public setHttp(http: ApiService): void {
    this.http = http;
  }

  public getHttpClient(): HttpClient {
    return this.httpClient;
  }

  public setHttpClient(httpClient: HttpClient): void {
    this.httpClient = httpClient;
  }

  public getContext(): microsoftTeams.Context {
    return this.context;
  }

  public setContext(context: microsoftTeams.Context): void {
    this.context = context;
  }
}
