/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Toast } from "bootstrap";
import { fromEvent, take } from "rxjs";
import { EventTypes } from "../EventTypes";

@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.less"],
})
export class ToastComponent implements OnInit {
  @Output() disposeEvent = new EventEmitter();

  @ViewChild("toastElement", { static: true }) toastEl!: ElementRef;

  @Input() type!: EventTypes;
  @Input() title!: string;
  @Input() message!: string;
  @Input() buttons!: { label: string; action: () => void }[];
  @Input() duration: number;

  toast!: Toast;

  ngOnInit() {
    this.show();
  }

  show() {
    this.toast = new Toast(
      this.toastEl.nativeElement,
      this.type === EventTypes.Error
        ? {
            autohide: false,
          }
        : {
            delay: this.duration,
          }
    );

    fromEvent(this.toastEl.nativeElement, "hidden.bs.toast")
      .pipe(take(1))
      .subscribe(() => this.hide());

    this.toast.show();
  }

  hide() {
    this.toast.dispose();
    this.disposeEvent.emit();
  }
}
