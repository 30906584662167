import { Injectable } from "@angular/core";
import { APP_CONFIG } from "../../environments/environment";
// import Logger from "../classes/Logger";

export interface ITimer {
  name: string;
  timer: Timer;
}

export type TimerType = Timer | AsyncTimer | ScheduledTimer;

@Injectable()
export class TimerService {
  private timers: ITimer[];
  private currentTick: number;
  private tickRate: number;

  public constructor() {
    this.timers = [];
    this.currentTick = 0;
    this.tickRate = APP_CONFIG.TIMER_TICK_RATE || 500;
    this.initialize();
  }

  public initialize(): void {
    let tick: number = this.getCurrentTick();
    setInterval(() => {
      for (const timer of this.getTimers()) {
        if (tick % timer.timer.getOnTick() == 0) {
          if (timer.timer.isTerminate()) {
            this.deleteTimer(timer.timer);
          } else if (!timer.timer.isPaused()) {
            timer.timer.run();
          }
        }
      }
      tick++;
      this.setCurrentTick(tick);

      // Logger.log("TimeService tick");
    }, this.getTickRate());
  }

  /**
   * Simplified function to add timers
   * @param timer
   */
  public setTimer(name: string, timer: TimerType): TimerType {
    timer.setName(name);

    this.getTimers().push({
      name: name,
      timer: timer,
    });

    return timer;
  }

  /**
   * Pauses a specific timer
   * @param timer
   */
  public pauseTimer(timer: TimerType): void {
    const _timer: ITimer = this.getTimers().find((i: ITimer) => i.timer == timer);

    if (_timer) {
      _timer.timer.pause();
    } else {
      throw new Error(`Timer ${_timer} does not exists.`);
    }
  }

  /**
   * Removes a timer
   * @param timer
   */
  public deleteTimer(timer: TimerType): void {
    this.setTimers(this.getTimers().filter((i: ITimer) => i.timer != timer));
  }

  public getTimer(name: string): TimerType {
    const _timer: ITimer = this.getTimers().find((timer: ITimer) => timer.name == name);
    return _timer ? _timer.timer : null;
  }

  /*
   * Getters & Setters
   */

  public getTimers(): ITimer[] {
    return this.timers;
  }

  public setTimers(timers: ITimer[]): void {
    this.timers = timers;
  }

  public getCurrentTick(): number {
    return this.currentTick;
  }

  public setCurrentTick(currentTick: number): void {
    this.currentTick = currentTick;
  }

  public getTickRate(): number {
    return this.tickRate;
  }

  public setTickRate(tickRate: number): void {
    this.tickRate = tickRate;
  }
}

export class Timer {
  private name: string;
  private onTick: number;
  private paused: boolean;
  private terminate: boolean;

  private body: () => void;

  public constructor(body: () => void, onTick: number) {
    this.body = body;
    this.onTick = onTick;
    this.paused = false;
  }

  public run(): void {
    try {
      this.getBody()();
    } catch (err) {
      throw new Error(`Fatal executing Timer body -> ${this.getName()}`);
    }
  }

  public runOnce(): void {
    try {
      this.run();
      this.setTerminate(true);
    } catch (err) {
      throw new Error(`Fatal executing Timer body -> ${this.getName()}`);
    }
  }

  public pause(): void {
    this.setPaused(true);
  }

  /*
   * Getters & Setters
   */

  public isTerminate(): boolean {
    return this.terminate;
  }

  public setTerminate(terminate: boolean): void {
    this.terminate = terminate;
  }

  public getBody(): () => void | Promise<void> {
    return this.body;
  }

  public setBody(body: () => void | Promise<void>): void {
    this.body = body;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getOnTick(): number {
    return this.onTick;
  }

  public setOnTick(onTick: number): void {
    this.onTick = onTick;
  }

  public isPaused(): boolean {
    return this.paused;
  }

  public setPaused(paused: boolean): void {
    this.paused = paused;
  }
}

export class AsyncTimer extends Timer {
  public constructor(body: () => Promise<void>, onTick: number) {
    super(body, onTick);
  }

  public async run(): Promise<void> {
    try {
      await this.getBody()();
    } catch (err) {
      throw new Error(`Fatal executing Timer body -> ${this.getName()}`);
    }
  }

  public async runOnce(): Promise<void> {
    try {
      await this.run();
      this.setTerminate(true);
    } catch (err) {
      throw new Error(`Fatal executing Timer body -> ${this.getName()}`);
    }
  }
}

export class ScheduledTimer extends Timer {
  public constructor(body: () => Promise<void>, onTick: number) {
    super(body, onTick);
  }
}
