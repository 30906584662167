import { AfterViewChecked, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { TableTemplate } from "../../components/table/table";
import { DialogService } from "../../components/dialog/dialog.service";
import { TableComponent } from "../../components/table/table.component";
import { EmployeeService } from "../../services/iota/employee.service";
import { MicrosoftService } from "../../services/microsoft.service";
import { SessionService } from "../../services/session.service";
import { ToastService } from "../../services/toast.service";
import { ActivatedRoute, Router } from "@angular/router";
interface TableValues {
  position: number;
  email: string;
  name: string;
  status: string;
  progress: string;
  deactivate: boolean;
  activate: boolean;
  resend: boolean;
  invite: boolean;
  upgrade: boolean;
}
@Component({
  selector: "app-employees",
  templateUrl: "./employees.component.html",
  styleUrls: ["./employees.component.less"],
})
export class EmployeesComponent implements OnInit, AfterViewChecked {
  private employee: EmployeeService;
  private dialog: DialogService;
  private microsoft: MicrosoftService;
  private session: SessionService;
  private toast: ToastService;
  private route: ActivatedRoute;
  private state: string;

  @ViewChild("table")
  public table: TableComponent;

  private tableId: string;
  private tableData: unknown[];
  private tableTemplates: TableTemplate[];

  @ViewChild("deactivateTemplate")
  public deactivateTemplate: TemplateRef<unknown>;

  @ViewChild("activateTemplate")
  public activateTemplate: TemplateRef<unknown>;

  @ViewChild("resendTemplate")
  public resendTemplate: TemplateRef<unknown>;

  @ViewChild("inviteTemplate")
  public inviteTemplate: TemplateRef<unknown>;

  @ViewChild("upgradeTemplate")
  public upgradeTemplate: TemplateRef<unknown>;

  private users: Map<string, string>;
  private emails: string[];
  private progress: number;
  public csv: string;

  public constructor(
    private router: Router,
    route: ActivatedRoute,
    dialog: DialogService,
    employee: EmployeeService,
    microsoft: MicrosoftService,
    session: SessionService,
    toast: ToastService
  ) {
    this.dialog = dialog;
    this.employee = employee;
    this.microsoft = microsoft;
    this.session = session;
    this.toast = toast;

    this.csv = "";

    this.route = route;

    this.tableId = "codes";
    this.tableData = [];
    this.tableTemplates = [];
    this.users = new Map<string, string>();
    this.progress = 0;
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  public setCsvUsers(users) {
    this.csv = users;
  }

  public async ngOnInit(): Promise<void> {
    this.setState(this.route.snapshot.paramMap.get("state"));

    this.setTableTemplates([
      {
        columnId: "deactivate",
        template: null,
      },
      {
        columnId: "activate",
        template: null,
      },
      {
        columnId: "resend",
        template: null,
      },
      {
        columnId: "invite",
        template: null,
      },
      {
        columnId: "upgrade",
        template: null,
      },
    ]);
  }

  public async ngAfterViewChecked(): Promise<void> {
    this.setTableTemplates([
      {
        columnId: "deactivate",
        template: this.getDeactivateTemplate(),
      },
      {
        columnId: "activate",
        template: this.getActivateTemplate(),
      },
      {
        columnId: "resend",
        template: this.getResendTemplate(),
      },
      {
        columnId: "invite",
        template: this.getInviteTemplate(),
      },
      {
        columnId: "upgrade",
        template: this.getUpgradeTemplate(),
      },
    ]);
  }

  /**
   * Deactivate Employee
   * @param index
   */
  public async deactivate(index: number): Promise<void> {
    const table = this.getTable();
    const data = table.getRow(index).getData();
    const email = data.get(table.getColumn("email"));

    data.set(table.getColumn("status"), "deactivated");
    data.set(table.getColumn("activate"), true);
    data.set(table.getColumn("deactivate"), false);
    data.set(table.getColumn("upgrade"), false);
    data.set(table.getColumn("invite"), false);
    data.set(table.getColumn("resend"), false);

    this.getDialog().close();

    const success = await this.getEmployee().deactivate(<string>email);

    this.getToast().open(success ? "✔" : "❌", success ? `${email} is gede-activeerd` : `Het is niet mogelijk ${email} te deactiveren`);
  }

  /**
   * Activate Employee
   * @param index
   */
  public async activate(index: number): Promise<void> {
    const table = this.getTable();
    const row = table.getRow(index);
    const data = row.getData();
    const email = data.get(table.getColumn("email"));

    data.set(table.getColumn("status"), "active");
    data.set(table.getColumn("activate"), false);
    data.set(table.getColumn("deactivate"), true);
    data.set(table.getColumn("upgrade"), true);
    data.set(table.getColumn("invite"), false);
    data.set(table.getColumn("resend"), true);

    this.getDialog().close();

    const success = await this.getEmployee().activate(<string>email);

    this.getToast().open(success ? "✔" : "❌", success ? `${email} is geactiveerd.` : `Het is niet mogelijk ${email} te activeren.`);
  }

  /**
   * Resend code to Employee
   * @param index
   */
  public async resend(index: number): Promise<void> {
    const table = this.getTable();
    const row = table.getRow(index);
    const data = row.getData();
    const email = data.get(table.getColumn("email"));
    const name = data.get(table.getColumn("name"));

    this.getDialog().close();

    const success = await this.getEmployee().sendCode(<string>email, <string>name);

    this.getToast().open(success ? "✔" : "❌", success ? `De uitnodigingsmail is verzonden naar ${email}` : `Het is niet mogelijk de uitnodigingsmail te versturen naar ${email}.<br>Wellicht is deze medewerker niet aanwezig in het systeem?`);
  }

  /**
   * Invite Employee
   * @param index
   */
  public async invite(index: number): Promise<void> {
    const table = this.getTable();
    const row = table.getRow(index);
    const data = row.getData();

    const email = data.get(table.getColumn("email"));
    const name = data.get(table.getColumn("name"));

    data.set(table.getColumn("status"), "active");
    data.set(table.getColumn("invite"), false);
    data.set(table.getColumn("deactivate"), true);
    data.set(table.getColumn("upgrade"), true);
    data.set(table.getColumn("resend"), true);

    this.getDialog().close();

    const success = await this.getEmployee().invite(<string>email, <string>name);

    this.getToast().open(success ? "✔" : "❌", success ? `${email} is succesvol uitgenodigd.` : `Het is niet mogelijk ${email} uit te nodigen.<br>Wellicht bestaat deze al?`);
  }

  /**
   * Increase balance of Employee
   * @param index
   * @param value
   */
  public async upgrade(index: number, value: string): Promise<void> {
    const table = this.getTable();
    const row = table.getRow(index);
    const data = row.getData();

    //const progress = table.getColumn("progress");
    const email = data.get(table.getColumn("email"));
    //const balance = <number>data.get(progress);

    // please let user refresh the data because IOTA needs time and user needs to verify himself that it succeeded. data.put(progress, balance + parseInt(value));

    this.getDialog().close();

    const success = await this.getEmployee().upgrade(<string>email, value);

    this.getToast().open(success ? "✔" : "❌", success ? `Succesvol ${value} verstuurd naar ${email}.Ververs het scherm en haal de medewerker(s) opnieuw op om het nieuwe tegoed te zien.` : `Het is niet mogelijk ${value} te versturen naar ${email}`);
  }

  /**
   * Retrieve table data based on row index and column label
   * @param index
   * @param columnName
   * @returns
   */
  public getData(index: number, columnName: string): unknown {
    const table = this.getTable();
    const row = table.getRow(index);
    const data = row.getData();

    return data.get(table.getColumn(columnName));
  }

  /**
   * Fills the table based on input field
   * @param value
   */
  public async fillTable(value: string): Promise<void> {
    if (!value) return;
    const lines = value.split("\n");
    const users = new Map<string, string>();
    const employee = this.getEmployee();

    for (let i = 0; i < lines.length; i++) {
      const part = lines[i].split(",");
      users.set(part[0], part[1] || part[0]);
    }

    this.setUsers(users);

    this.setTableData([]);
    this.setProgress(0);

    const unique = Array.from(users.keys());
    const tableData: TableValues[] = [];

    let total = 0;

    do {
      let finished = 0;
      try {
        total += await new Promise<number>((resolve) => {
          const ref = unique.length - total;
          const till = ref > 25 ? 25 : ref;
          for (let j = 0; j < till; j++) {
            const user = unique[total + j];
            const email = user;
            const name = users.get(user);

            employee.info(email).then((res) => {
              const index = tableData.length;
              tableData.push({
                position: index + 1,
                email: email,
                name: name,
                status: this.statusTranslator(res.status),
                progress: `€ ${res.balance.toFixed(2)}`,
                deactivate: res.status == "active",
                activate: res.status == "deactivated",
                resend: res.status == "active",
                invite: res.status == "unknown",
                upgrade: res.status == "active",
              });

              finished++;
              if (finished >= 25 || total + finished >= unique.length) {
                resolve(finished);
              }

              this.setProgress((100 / unique.length) * (total + finished));
            });
          }
        });

        console.log(`${total}/${unique.length}`);
      } catch (err) {
        console.error(`[USER][${unique}] Error getting info user`, err);
      }
    } while (total < unique.length);

    this.setProgress(100);
    this.setTableData(tableData);
  }

  statusTranslator(status: string) {
    switch (status) {
      case "active":
        return "Actief";
      case "deactivated":
        return "Gedeactiveerd";
      case "unknown":
        return "Niet uitgenodigd";
    }
  }

  /**
   * Mass invite Employees
   * @blockSize 25
   * @param value
   */
  public async inviteMassEmployee(value: string): Promise<void> {
    const employee = this.getEmployee();
    const success = await this.mass(value, employee.invite, employee);

    this.getToast().open(success ? "✔" : "❌", success ? `${success} medewerker(s) uitgenodigd.` : "Het is niet mogelijk de medewerker(s) uit te nodigen.");
  }

  /**
   * Mass create users
   * @blockSize 25
   * @param value
   */
  public async createMass(value: string): Promise<void> {
    const microsoft = this.getMicrosoft();
    const success = await this.mass(value, microsoft.createUser, microsoft);

    this.getToast().open(success ? "✔" : "❌", success ? `${success} gebruiker(s) aangemaakt` : "Het is niet mogelijk gebruiker(s) aan te maken");
  }

  /**
   * Mass invite users
   * @blockSize 25
   * @param value
   */
  public async inviteMassUser(value: string): Promise<void> {
    const microsoft = this.getMicrosoft();
    const success = <unknown>await this.mass(value, microsoft.inviteUser, microsoft);
    this.getToast().open(success ? "✔" : "❌", success ? ` ${success} gebruiker(s) uitgenodigd` : "Het is niet mogelijk gebruiker(s) uit te nodigen");
  }

  /**
   * Mass action on user
   * @param value
   * @param action
   */
  private async mass(value: string, action: (email: string, name: string) => Promise<unknown> | ((email: string) => Promise<unknown>), source: unknown): Promise<number> {
    const lines = value.split("\n");
    const users = this.getUsers();

    for (let i = 0; i < lines.length; i++) {
      const part = lines[i].split(",");
      users.set(part[0], (part[1] || "unknown").replace(/\s/g, ""));
    }

    const unique = Array.from(users.keys());
    let total = 0;
    let success = 0;

    for (let i = 0; i < unique.length; i += 25) {
      let finished = 0;
      try {
        total += await new Promise<number>((resolve) => {
          const ref = unique.length - total;
          const till = ref > 25 ? 25 : ref;
          for (let j = 0; j < till; j++) {
            const user = unique[i + j];
            const email = user;
            const name = users.get(user);

            action
              .bind(source)(email, name)
              .then((res: boolean) => {
                finished++;
                if (finished >= 25 || total + finished >= unique.length) {
                  resolve(finished);
                }

                if (res) success++;
              });
          }
        });
      } catch (err) {
        console.error(err);
      }
    }

    return success;
  }

  /*
   * Getters & Setters
   */

  public getEmployee(): EmployeeService {
    return this.employee;
  }

  public setEmployee(employee: EmployeeService): void {
    this.employee = employee;
  }

  public getDialog(): DialogService {
    return this.dialog;
  }

  public setDialog(dialog: DialogService): void {
    this.dialog = dialog;
  }

  public getTableId(): string {
    return this.tableId;
  }

  public setTableId(tableId: string): void {
    this.tableId = tableId;
  }

  public getTableData(): unknown[] {
    return this.tableData;
  }

  public setTableData(tableData: unknown[]): void {
    this.tableData = tableData;
  }

  public getTableTemplates(): TableTemplate[] {
    return this.tableTemplates;
  }

  public setTableTemplates(tableTemplates: TableTemplate[]): void {
    this.tableTemplates = tableTemplates;
  }

  public getEmails(): string[] {
    return this.emails;
  }

  public setEmails(emails: string[]): void {
    this.emails = emails;
  }

  public getDeactivateTemplate(): TemplateRef<unknown> {
    return this.deactivateTemplate;
  }

  public setDeactivateTemplate(deactivateTemplate: TemplateRef<unknown>): void {
    this.deactivateTemplate = deactivateTemplate;
  }

  public getActivateTemplate(): TemplateRef<unknown> {
    return this.activateTemplate;
  }

  public setActivateTemplate(activateTemplate: TemplateRef<unknown>): void {
    this.activateTemplate = activateTemplate;
  }

  public getResendTemplate(): TemplateRef<unknown> {
    return this.resendTemplate;
  }

  public setResendTemplate(resendTemplate: TemplateRef<unknown>): void {
    this.resendTemplate = resendTemplate;
  }

  public getInviteTemplate(): TemplateRef<unknown> {
    return this.inviteTemplate;
  }

  public setInviteTemplate(inviteTemplate: TemplateRef<unknown>): void {
    this.inviteTemplate = inviteTemplate;
  }

  public getUpgradeTemplate(): TemplateRef<unknown> {
    return this.upgradeTemplate;
  }

  public setUpgradeTemplate(upgradeTemplate: TemplateRef<unknown>): void {
    this.upgradeTemplate = upgradeTemplate;
  }

  public getUsers(): Map<string, string> {
    return this.users;
  }

  public setUsers(users: Map<string, string>): void {
    this.users = users;
  }

  public getProgress(): number {
    return this.progress;
  }

  public setProgress(progress: number): void {
    this.progress = progress;
  }

  public getTable(): TableComponent {
    return this.table;
  }

  public setTable(table: TableComponent): void {
    this.table = table;
  }

  public getMicrosoft(): MicrosoftService {
    return this.microsoft;
  }

  public setMicrosoft(microsoft: MicrosoftService): void {
    this.microsoft = microsoft;
  }

  public getSession(): SessionService {
    return this.session;
  }

  public setSession(session: SessionService): void {
    this.session = session;
  }

  public getToast(): ToastService {
    return this.toast;
  }

  public setToast(toast: ToastService): void {
    this.toast = toast;
  }

  public getState(): string {
    return this.state;
  }

  public setState(state: string): void {
    this.state = state;
  }

  // public getCsvForm(): FormGroup {
  //   return this.csvForm;
  // }

  // public setCsvForm(csvForm: FormGroup): void {
  //   this.csvForm = csvForm;
  // }
}
