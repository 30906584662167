import { Injectable, TemplateRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogComponent } from "./dialog.component";

interface DialogInterface {
  template: TemplateRef<unknown>;
  data?: unknown;
}

@Injectable({
  providedIn: "root",
})
export class DialogService {
  private dialog: MatDialog;
  private ref: MatDialogRef<unknown>;

  public constructor(dialog: MatDialog) {
    this.dialog = dialog;
  }

  public open(dialog: DialogInterface): void {
    this.setRef(
      this.getDialog().open(DialogComponent, {
        data: {
          template: dialog.template,
          data: dialog.data,
        },
      })
    );
  }

  public show(template: TemplateRef<unknown>, data = {}): void {
    this.setRef(
      this.getDialog().open(DialogComponent, {
        data: {
          template: template,
          data: data,
        },
      })
    );
  }

  public close() {
    this.getRef().close();
  }

  /*
   * Getters & Setters
   */

  public getDialog(): MatDialog {
    return this.dialog;
  }

  public setDialog(dialog: MatDialog): void {
    this.dialog = dialog;
  }

  public getRef(): MatDialogRef<unknown> {
    return this.ref;
  }

  public setRef(ref: MatDialogRef<unknown>): void {
    this.ref = ref;
  }
}
