import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ApiService } from "../api.service";

interface EmployeeInfo {
  status: string;
  balance: number;
}

@Injectable()
export class EmployeeService {
  private api: ApiService;

  public constructor(api: ApiService) {
    this.api = api;
  }

  /**
   * Resend QR to employee
   * @param email
   */
  public async sendCode(email: string, name: string): Promise<boolean> {
    const api = this.getApi().get("default");

    try {
      const res = await firstValueFrom(
        api.post("employee/resendQR", {
          email: email,
          firstName: name,
          lastName: "unknown",
        })
      );
      return !!res["data"].success;
    } catch (err) {
      console.error(`[USER][${email}] Unable to resend code`, {
        error: err,
      });
      return false;
    }
  }

  /**
   * Invite employee
   * @param email
   */
  public async invite(email: string, name: string): Promise<boolean> {
    const api = this.getApi().get("default");

    try {
      const res = await firstValueFrom(
        api.post("employee/invite", {
          firstName: name,
          lastName: "unknown",
          email: email,
        })
      );
      return !!res["data"].success;
    } catch (err) {
      console.error(`[USER][${email}] Unable to invite user`, {
        error: err,
      });
      return false;
    }
  }

  /**
   * Upgrade employee balance
   * @param email
   */
  public async upgrade(email: string, value: number | string): Promise<boolean> {
    const _value = parseInt(<string>value);
    const api = this.getApi().get("default");

    try {
      const res = await firstValueFrom(
        api.post("employee/upgrade", {
          email: email,
          amount: _value,
        })
      );
      return !!res["data"].success;
    } catch (err) {
      console.error(`[USER][${email}] Unable to upgrade user balance`, {
        error: err,
      });
      return false;
    }
  }

  /**
   * Activate employee
   * @param email
   */
  public async activate(email: string): Promise<boolean> {
    return await this.changeStatus(email, "active");
  }

  /**
   * Deactivate employee
   * @param email
   */
  public async deactivate(email: string): Promise<boolean> {
    return await this.changeStatus(email, "deactivated");
  }

  /**
   * Change status of employee
   * @param email
   * @param status
   * @returns
   */
  private async changeStatus(email: string, status: string): Promise<boolean> {
    const api = this.getApi().get("default");
    try {
      const res = await firstValueFrom(
        api.post("employee/changestate", {
          email: email,
          status: status,
        })
      );
      return !!res["data"].success;
    } catch (err) {
      console.error(`[USER][${email}] Unable to change status -> ${status}`, {
        error: err,
      });
      return false;
    }
  }

  /**
   * Retrieve info of user
   * @param email
   * @returns
   */
  public async info(email: string): Promise<EmployeeInfo> {
    const api = this.getApi().get("default");
    try {
      const res = await firstValueFrom(
        api.post("employee/info", {
          email: email,
        })
      );
      const data = res["data"].success.data;

      return {
        status: data.status,
        balance: data.balance,
      };
    } catch (err) {
      console.log(`[USER][${email}] Unable get user info`, err);
      return null;
    }
  }

  /*
   * Getters & Setters
   */

  public getApi(): ApiService {
    return this.api;
  }

  public setApi(api: ApiService): void {
    this.api = api;
  }
}
