import { Component, EventEmitter, OnInit, Output} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DialogService } from "../dialog/dialog.service";


@Component({
  selector: "app-csv-block",
  templateUrl: "./csv-block.component.html",
  styleUrls: ["./csv-block.component.less"]
})
export class CsvBlockComponent implements OnInit {

  @Output() csvEventEmitter = new EventEmitter<string>();

  private users: Map<string, string>;
  private csvForm: FormGroup;
  private dialog: DialogService;

  constructor(dialog: DialogService,) {
    this.dialog = dialog;
    this.users = new Map<string, string>();
    this.csvForm = new FormGroup({
      csvTextArea: new FormControl("")
    });
  }

  ngOnInit(): void {
    this.csvForm.controls["csvTextArea"].valueChanges.subscribe((csvTextArea: string) => {
      const csvText = this.formatTextAreaContent(csvTextArea);
      this.csvForm.controls["csvTextArea"].patchValue(csvText, { emitEvent: false });
      this.csvEventEmitter.emit(csvText);
      // console.log("EMIT");
    });
  }

  /**
  * Upload contents of csv to email array
  * @param event
  */
  public uploadCSV(event: Event): void {
    const file = event.target["files"][0];

    try {

      if (file.type != "text/csv") throw new Error("File is not of the type CSV");
      const reader = new FileReader();
      const users = new Map<string, string>();

      reader.onloadend = () => {
        const lines = (<string>reader.result).split("\r\n");
        for (let i = 0; i < lines.length; i++) {
          const part = lines[i].split(",");
          users.set(part[0].toLowerCase(), part[1] || part[0]);
        }
        this.setUsers(users);

        //read textarea if 
        this.showCSV();
      };
      reader.readAsText(file);

    } catch (err) {
      alert(err);
    }
    (<HTMLInputElement>event.target).value = "";

  }

  /** 
 * Format input after textarea content changes
 * @returns string
 */
  public formatTextAreaContent(textAreaContent: string) {
    const lines = textAreaContent.split("\n");
    const newLines = lines.map((line: string) => {

      const first = line.split(",")[0].toLowerCase();
      let createdLine = "";

      if (line.split(",").length > 1) {
        const second = line.split(",")[1];
        createdLine = first + "," + second;
      } else {
        createdLine = first;
      }

      return createdLine;
    });
    return newLines.join("\n");
  }

  /**
   * Retrieve contencts in csv as string
   * @returns string[]
   */
  public showCSV(): void {
    const users = this.getUsers();
    const csvTextArea = Array.from(users.keys()).map((email) => {
      return `${email}, ${users.get(email)}`;
    });
    this.getCsvForm().controls["csvTextArea"].setValue(csvTextArea.join("\n"));
  }

  public getUsers(): Map<string, string> {
    return this.users;
  }

  public setUsers(users: Map<string, string>): void {
    this.users = users;
  }

  public getCsvForm(): FormGroup {
    return this.csvForm;
  }

  public setCsvForm(csvForm: FormGroup): void {
    this.csvForm = csvForm;
  }

  public getDialog(): DialogService {
    return this.dialog;
  }

  public setDialog(dialog: DialogService): void {
    this.dialog = dialog;
  }

}
