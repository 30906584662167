import { Injectable } from "@angular/core";
import { IpcRenderer, WebFrame } from "electron";
import { ChildProcess } from "child_process";

@Injectable({
  providedIn: "root",
})
export class ElectronService {
  private electron: boolean;
  private ipcRenderer: IpcRenderer;
  private webFrame: WebFrame;
  private childProcess: ChildProcess;

  public constructor() {
    this.electron = !!(window && window.process && window.process.type);
    if (this.electron) {
      this.ipcRenderer = window.require("electron").ipcRenderer;
      this.webFrame = window.require("electron").webFrame;
      this.childProcess = window.require("child_process");
    }
  }

  /**
   * Emits an electron event
   * @param channel the event id
   * @param args arguments
   */
  public emitEvent(channel: string, ...args: unknown[]): void {
    this.getIpcRenderer().send(channel, args);
  }

  /*
   * Getters & Setters
   */

  public isElectron(): boolean {
    return this.electron;
  }

  public setElectron(electron: boolean): void {
    this.electron = electron;
  }

  public getIpcRenderer(): IpcRenderer {
    return this.ipcRenderer;
  }

  public setIpcRenderer(ipcRenderer: IpcRenderer): void {
    this.ipcRenderer = ipcRenderer;
  }

  public getWebFrame(): WebFrame {
    return this.webFrame;
  }

  public setWebFrame(webFrame: WebFrame): void {
    this.webFrame = webFrame;
  }

  public getChildProcess(): ChildProcess {
    return this.childProcess;
  }

  public setChildProcess(childProcess: ChildProcess): void {
    this.childProcess = childProcess;
  }
}
