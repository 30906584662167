import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Node } from "../classes/node";
import { ApiService } from "./api.service";
import { ApplicationService } from "./application.service";

@Injectable({
  providedIn: "root",
})
export class NodeService {
  private app: ApplicationService;
  private api: ApiService;

  private nodes: Map<string, Node>;

  public constructor(api: ApiService) {
    this.api = api;

    this.nodes = new Map<string, Node>();
  }

  public async initialize(): Promise<void> {
    // await this.initializeNodes();
  }

  public async initializeNodes(): Promise<void> {
    const api = this.getApi().get("default");
    const nodes = this.getNodes();

    try {
      const res = await firstValueFrom(api.post("node/info/all"));

      for (const data of res["data"].success.data) {
        const node = new Node(data.name);
        node.update({
          version: data.version,
          healthy: data.isHealthy,
          networkId: data.networkId,
          bech32HRP: data.bech32HRP,
          minPoWScore: data.minPoWScore,
          messagesPerSecond: data.messagesPerSecond,
          referencedMessagesPerSecond: data.referencedMessagesPerSecond,
          referencedRate: data.referencedRate,
          latestMilestoneTimestamp: data.latestMilestoneTimestamp,
          latestMilestoneIndex: data.latestMilestoneIndex,
          confirmedMilestoneIndex: data.confirmedMilestoneIndex,
          pruningIndex: data.pruningIndex,
          features: data.features,
        });
        nodes.set(`NODE-${Array.from(nodes).length}`, node);
      }
    } catch (err) {
      console.error(err);
    }
  }

  /*
   * Getters & Setters
   */

  public getApi(): ApiService {
    return this.api;
  }

  public setApi(api: ApiService): void {
    this.api = api;
  }

  public getNodes(): Map<string, Node> {
    return this.nodes;
  }

  public setNodes(nodes: Map<string, Node>): void {
    this.nodes = nodes;
  }
}
