import { Inject, Injectable, OnDestroy } from "@angular/core";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AccountInfo, InteractionStatus, RedirectRequest } from "@azure/msal-browser";
import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { SessionRole } from "../classes/sessionRole";
import jwt_decode from "jwt-decode";
import { ApiService } from "./api.service";
import { NodeService } from "./node.service";
import { ToastService } from "./toast.service";

@Injectable({
  providedIn: "root",
})
export class SessionService implements OnDestroy {
  private msal: MsalService;

  private browser: string;
  private roles: SessionRole[];

  private config: MsalGuardConfiguration;
  private broadcast: MsalBroadcastService;
  private api: ApiService;
  private node: NodeService;
  private toast: ToastService;

  private loggedIn: boolean;

  private name: string;

  private readonly _destroying$ = new Subject<void>();

  public constructor(
    msal: MsalService,
    broadcast: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) config: MsalGuardConfiguration,
    api: ApiService,
    node: NodeService,
    toast: ToastService
  ) {
    this.msal = msal;
    this.config = config;
    this.broadcast = broadcast;
    this.api = api;
    this.node = node;
    this.toast = toast;

    this.browser = "Unknown";
    this.roles = [SessionRole.GUEST];
    this.name = "...";
    this.loggedIn = false;

    this.intitializeMsal();
  }

  /**
   * Initialize session
   */
  public initialize(): void {
    this.setBrowser(<string>this.checkBrowser());
  }

  /**
   * Initialize msal events & login events
   */
  private intitializeMsal(): void {
    const msal = this.getMsal();
    const broadcast = this.getBroadcast();

    msal.instance.enableAccountStorageEvents();

    broadcast.msalSubject$.subscribe((res) => {
      switch (res.eventType) {
        case "msal:handleRedirectStart":
          if (msal.instance.getAllAccounts().length) this.handleLogin();
          break;

        case "msal:loginFailure":
          this.setLoggedIn(false);
          break;

        case "msal:acquireTokenSuccess":
        case "msal:loginSuccess":
          sessionStorage.setItem("JWT", res.payload["idToken"]);
          this.handleLogin();
          break;

        case "msal:handleRedirectEnd":
          // done with event
          break;
      }
    });

    broadcast.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => this.checkAndSetActiveAccount());
  }

  public ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  public checkAndSetActiveAccount(): void {
    const msal = this.getMsal();
    //TODO check if necessary
    if (!msal.instance.getActiveAccount() && msal.instance.getAllAccounts().length > 0) msal.instance.setActiveAccount(msal.instance.getAllAccounts()[0]);
  }

  /**
   * Handles the user login and sets roles
   */
  public handleLogin(): void {
    const msal = this.getMsal();

    try {
      const account = msal.instance.getAllAccounts()[0];
      const api = this.getApi().get("default");
      const jwt = sessionStorage.getItem("JWT");
      const parsedJWT = jwt_decode(jwt);
      const groups = parsedJWT["extension_vitknip_groups"].split(",").map((group: string) => {
        switch (group) {
          case "sg-vk-financial":
            return SessionRole.FINANCIAL;

          case "sg-vk-hr":
            return SessionRole.HR;

          case "sg-vk-admins":
            return SessionRole.ADMIN;

          case "sg-vk-retailers":
            return SessionRole.RETAILER;

          default:
            console.warn("uknown group in jwt => ", group);
            break;
        }
      });

      api.setDefaultHeaders({
        Authorization: `Bearer ${jwt}`,
      });
      this.setRoles([...groups, SessionRole.USER]);
      this.setName(account.name !== "unknown" ? account.name : account.username);
      this.setLoggedIn(true);
      this.getNode().initializeNodes();

      this.getToast().open("✔", "Successfully logged in");
    } catch (err) {
      console.error(err);
      this.setLoggedIn(false);
      sessionStorage.clear();
      localStorage.clear();
      this.getToast().open("✔", "Could not log in");
    }
  }

  /**
   * Retrieves the account information from MsalService
   * @returns AccountInfo
   */
  public getActiveAccount(): AccountInfo {
    return this.getMsal().instance.getActiveAccount();
  }

  /**
   * Login user
   */
  public login(): void {
    const msal = this.getMsal();
    const config = this.getConfig();

    console.log("Login => ", {
      msal: msal,
      config: config,
    });

    if (config.authRequest) {
      msal.loginRedirect({
        ...config.authRequest,
      } as RedirectRequest);
    } else {
      msal.loginRedirect();
    }
  }

  /**
   * Logout user
   */
  public logout(): void {
    this.getMsal().logoutRedirect();
    sessionStorage.clear();
  }

  /**
   * Check what browser the session is on.
   * @returns The browser name
   */
  public checkBrowser(): string {
    const browserAgents = [
      {
        name: "Chrome",
        regex: /chrome|chromium|crios/i,
      },
      {
        name: "Firefox",
        regex: /firefox|fxios/i,
      },
      {
        name: "Safari",
        regex: /safari/i,
      },
      {
        name: "Opera",
        regex: /opr\//i,
      },
      {
        name: "Edge",
        regex: /edg/i,
      },
    ];

    for (const browser of browserAgents) {
      if (navigator.userAgent.match(browser.regex)) return browser.name;
    }

    return "Unknown";
  }

  public hasRole(index: number): boolean {
    return this.getRoles().includes(index);
  }

  /* 
    Getters and setters
  */

  public getMsal(): MsalService {
    return this.msal;
  }

  public setMsal(msal: MsalService): void {
    this.msal = msal;
  }

  public getConfig(): MsalGuardConfiguration {
    return this.config;
  }

  public setConfig(config: MsalGuardConfiguration): void {
    this.config = config;
  }

  public getBroadcast(): MsalBroadcastService {
    return this.broadcast;
  }

  public setBroadcast(broadcast: MsalBroadcastService): void {
    this.broadcast = broadcast;
  }

  public getBrowser(): string {
    return this.browser;
  }

  public setBrowser(browser: string): void {
    this.browser = browser;
  }

  public getRoles(): SessionRole[] {
    return this.roles;
  }

  public setRoles(roles: SessionRole[]): void {
    this.roles = roles;
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public setLoggedIn(loggedIn: boolean): void {
    this.loggedIn = loggedIn;
  }

  public getApi(): ApiService {
    return this.api;
  }

  public setApi(api: ApiService): void {
    this.api = api;
  }

  public getName(): string {
    return this.name;
  }

  public setName(name: string): void {
    this.name = name;
  }

  public getNode(): NodeService {
    return this.node;
  }

  public setNode(node: NodeService): void {
    this.node = node;
  }

  public getToast(): ToastService {
    return this.toast;
  }

  public setToast(toast: ToastService): void {
    this.toast = toast;
  }
}
