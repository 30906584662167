<div class="actionsContainer">
    <ul>
        <li>
            <label class="full file-upload mat-stroked-button" for="upload">
                <mat-icon>chevron_right</mat-icon>
                <span>{{ "SCREENS.EMPLOYEES.UPLOAD.BUTTONS.upload" | translate }}</span>
            </label>
            <input id="upload" type="file" (change)="uploadCSV($event)" />
        </li>
    </ul>
</div>

<span>
    <mat-form-field appearance="outline">
        <form [formGroup]="getCsvForm()">
            <textarea #csv formControlName="csvTextArea" matInput></textarea>
        </form>
    </mat-form-field>
</span>