import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TableVirtualScrollModule } from "ng-table-virtual-scroll";
import { AppRoutingModule } from "./app-routing.module";
import { DialogComponent } from "./components/dialog/dialog.component";

import { LanguageModule } from "./modules/language.module";
import { MaterialModule } from "./modules/material.module";
import { MsalModule as AuthModule } from "./modules/msal.module";

import { RootComponent } from "./screens/root/root";
import { ApiService } from "./services/api.service";
import { ApplicationService } from "./services/application.service";
import { DialogService } from "./components/dialog/dialog.service";
import { ElectronService } from "./services/electron.service";
import { GraphQLService } from "./services/graphql.service";
import { SessionService } from "./services/session.service";
import { ToastService } from "./services/toast.service";
import { TeamsService } from "./services/teams.service";
import { TimerService } from "./services/timer.service";
import { HeaderComponent } from "./components/header/header.component";
import { EmployeesComponent } from "./screens/employees/employees.component";
import { RetailersComponent } from "./screens/retailers/retailers.component";
import { FinancialComponent } from "./screens/financial/financial.component";
import { TableComponent } from "./components/table/table.component";
import { DashboardComponent } from "./screens/dashboard/dashboard.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { MsalRedirectComponent } from "@azure/msal-angular";
import { ToastComponent } from "./components/snacks/toast/toast.component";
import { ToasterComponent } from "./components/snacks/toaster/toaster.component";
import { EmployeeService } from "./services/iota/employee.service";
import { MicrosoftService } from "./services/microsoft.service";
import { UsersComponent } from "./screens/users/users.component";
import { CsvBlockComponent } from "./components/csv-block/csv-block.component";

@NgModule({
  declarations: [
    RootComponent,
    DialogComponent,
    HeaderComponent,
    EmployeesComponent,
    RetailersComponent,
    FinancialComponent,
    TableComponent,
    DashboardComponent,
    ToastComponent,
    ToasterComponent,
    UsersComponent,
    CsvBlockComponent,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    TableVirtualScrollModule,
    LanguageModule,
    AuthModule,
  ],
  providers: [
    ApiService,
    ApplicationService,
    DialogService,
    ElectronService,
    ToastService,
    GraphQLService,
    TimerService,
    TeamsService,
    SessionService,
    EmployeeService,
    MicrosoftService,
  ],
  bootstrap: [RootComponent, MsalRedirectComponent],
})
export class AppModule {}
