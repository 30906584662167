import { Component, OnInit, ViewContainerRef } from "@angular/core";
import { APP_CONFIG } from "../../../environments/environment";
import { ApiService } from "../../services/api.service";
import { ApplicationService } from "../../services/application.service";
import { NodeService } from "../../services/node.service";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

@Component({
  selector: "app-root",
  templateUrl: "./root.html",
  styleUrls: ["./root.less"],
  animations: [],
})
export class RootComponent implements OnInit {
  private app: ApplicationService;
  private api: ApiService;
  private node: NodeService;

  public constructor(app: ApplicationService, api: ApiService, node: NodeService, public ref: ViewContainerRef) {
    this.app = app;
    this.api = api;
    this.node = node;
  }

  public async ngOnInit(): Promise<void> {
    const app = this.getApp();
    const api = this.getApi();
    const node = this.getNode();
    api.add("default", APP_CONFIG.API);

    await app.initialize();
    await node.initialize();

    app.setLoading(false);

    getAnalytics(
      initializeApp({
        apiKey: "AIzaSyDBhnsH8I8Cqyq_P8zgp-I5PCwdidoyWgw",
        authDomain: "vitknip.firebaseapp.com",
        projectId: "vitknip",
        storageBucket: "vitknip.appspot.com",
        messagingSenderId: "638863003302",
        appId: "1:638863003302:web:6dc41e30e7ce2bfb906e67",
      })
    );
  }

  /*
   * Getters & Setters
   */

  public getApp(): ApplicationService {
    return this.app;
  }

  public setApp(app: ApplicationService): void {
    this.app = app;
  }

  public getApi(): ApiService {
    return this.api;
  }

  public setApi(api: ApiService): void {
    this.api = api;
  }

  public getNode(): NodeService {
    return this.node;
  }

  public setNode(node: NodeService): void {
    this.node = node;
  }
}
