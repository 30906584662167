import { Component } from "@angular/core";

@Component({
  selector: "app-retailers",
  templateUrl: "./retailers.component.html",
  styleUrls: ["./retailers.component.less"],
})
export class RetailersComponent {
  public constructor() {
    console.log("Retailers");
  }

  /*
   * Getters & Setters
   */
}
