import { Component } from "@angular/core";
import { NodeService } from "../../services/node.service";
import { SessionService } from "../../services/session.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.less"],
})
export class HeaderComponent {
  private node: NodeService;
  private session: SessionService;
  public constructor(node: NodeService, session: SessionService) {
    this.node = node;
    this.session = session;
  }

  /*
   * Getters & Setters
   */

  public getNode(): NodeService {
    return this.node;
  }

  public setNode(node: NodeService): void {
    this.node = node;
  }

  public getSession(): SessionService {
    return this.session;
  }

  public setSession(session: SessionService): void {
    this.session = session;
  }
  public getVersion(): string {
    return "1.0";
  }


}
