import { TemplateRef } from "@angular/core";
import { TableVirtualScrollDataSource } from "ng-table-virtual-scroll";
import { Column } from "./column";
import { Filter } from "./filter";
import { Row } from "./row";

export interface TableTemplate {
  columnId: string;
  template: TemplateRef<unknown>;
}

export class Table {
  private id: string;

  private columns: Map<number, Column>;
  private rows: Map<number, Row>;
  private filters: Map<Column, Filter>;
  private templates: Map<Column, TemplateRef<unknown>>;

  private initialRows: unknown[];

  private dataSource: TableVirtualScrollDataSource<unknown>;

  private loading: boolean;

  public constructor() {
    this.columns = new Map<number, Column>();
    this.rows = new Map<number, Row>();
    this.filters = new Map<Column, Filter>();
    this.templates = new Map<Column, TemplateRef<unknown>>();

    this.dataSource = new TableVirtualScrollDataSource();

    this.loading = true;
  }

  public initialize(id: string, rows: unknown[], templates: TableTemplate[]): void {
    this.setLoading(true);
    this.setId(id);
    this.initRows(rows);
    this.setInitialRows(rows);
    if (templates.length) this.initTemplates(templates);
    this.setLoading(false);
  }

  /**
   * Add a row to the table
   * @param row
   */
  public addRow(row: Row): void {
    const rows = Array.from(this.getRows().values());
    this.getRows().set(rows.length, row);
  }

  /**
   * Remove row based on index
   * @param index
   */
  public deleteRow(index: number): void {
    this.getRows().delete(index);
  }

  /**
   * Retrieve row based on index
   * @param index
   * @returns
   */
  public getRow(index: number): Row {
    return this.getRows().get(index);
  }

  /**
   * Add a column to the table
   * @param column
   */
  public addColumn(column: Column): void | boolean {
    const columns = Array.from(this.getColumns().values());

    for (const _column of columns) {
      if (_column.getLabel() == column.getLabel()) return false;
    }

    this.getColumns().set(columns.length, column);
  }

  /**
   * Sets the rows
   * @param rows
   */
  private initRows(rows: unknown[]): void {
    const map = this.getRows();

    for (let i = 0; i < rows.length; i++) {
      const dataMap = new Map<Column, unknown>();

      for (const data of Object.keys(rows[i])) {
        let column = this.getColumn(data);
        if (!column) {
          column = new Column(data, data);
          this.addColumn(column);
        }
        dataMap.set(column, rows[i][data]);
      }
      map.set(i, new Row(`${i}`, dataMap));
    }

    this.setRows(map);
    this.updateRows(map);
    this.updateColumns();
  }

  /**
   * Sets the templates
   * @param templates
   */
  private initTemplates(templates: TableTemplate[]): void {
    const map = this.getTemplates();
    for (const template of templates) {
      const column = this.getColumnById(template.columnId);
      if (column) map.set(column, template.template);
    }
    this.setTemplates(map);
  }

  /**
   * Return template based on column
   * @param column
   * @returns
   */
  public getTemplate(column: Column): TemplateRef<unknown> {
    return this.getTemplates().get(column);
  }

  /**
   * Update the columns
   */
  public updateColumns(): void {
    this.setColumns(new Map<number, Column>());

    for (const row of Array.from(this.getRows().values())) {
      const columnArr = Array.from(row.getData().keys());
      for (const column of columnArr) {
        this.addColumn(column);
      }
    }
  }

  /**
   * Update the rows
   * @param rows
   */
  public updateRows(rows: Map<number, Row>): void {
    this.getDataSource().data = Array.from(rows.values()).map((row: Row) => {
      return row.getDataAsObject();
    });
  }

  /**
   * Return column by label
   * @param label
   * @returns
   */
  public getColumn(label: string): Column {
    return Array.from(this.getColumns()
      .values())
      .find((column: Column) => column.getLabel() == label);
  }

  /**
   * Return column by columnId
   * @param id
   * @returns
   */
  public getColumnById(id: string): Column {
    return Array.from(this.getColumns()
      .values())
      .find((column: Column) => column.getId() == id);
  }

  /**
   * Retrieve all column labels
   * @returns
   */
  public getColumnDef(): string[] {
    return Array.from(this.getColumns()
      .values())
      .sort((a, b) => a.getIndex() - b.getIndex())
      .map((column) => (column.isActive() ? column.getLabel() : null))
      .filter((i) => i);
  }

  /**
   * Logger for HTML
   * @param msg
   */
  public log(msg: unknown): void {
    console.warn("TABLE MESSAGE => ", msg);
  }

  /**
   * Reload the table
   */
  public update(): void {
    this.loading = true;
    this.updateRows(this.getRows());
    this.updateColumns();
    this.loading = false;
  }

  /**
   * Refresh the table
   */
  public refresh(): void {
    this.setDataSource(new TableVirtualScrollDataSource());
    this.setRows(new Map<number, Row>());
    this.setColumns(new Map<number, Column>());

    this.initialize(this.getId(), this.getInitialRows(), []);

    console.error(this.getInitialRows(), this.getDataSource().data);
  }

  /*
   * Getters & Setters
   */

  public getId(): string {
    return this.id;
  }

  public setId(id: string): void {
    this.id = id;
  }

  public getInitialRows(): unknown[] {
    return this.initialRows;
  }

  public setInitialRows(initialRows: unknown[]): void {
    this.initialRows = initialRows;
  }

  public getColumns(): Map<number, Column> {
    return this.columns;
  }

  public setColumns(columns: Map<number, Column>): void {
    this.columns = columns;
  }

  public getRows(): Map<number, Row> {
    return this.rows;
  }

  public setRows(rows: Map<number, Row>): void {
    this.rows = rows;
  }

  public getFilters(): Map<Column, Filter> {
    return this.filters;
  }

  public setFilters(filters: Map<Column, Filter>): void {
    this.filters = filters;
  }

  public getDataSource(): TableVirtualScrollDataSource<unknown> {
    return this.dataSource;
  }

  public setDataSource(dataSource: TableVirtualScrollDataSource<unknown>): void {
    this.dataSource = dataSource;
  }

  public isLoading(): boolean {
    return this.loading;
  }

  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  public getTemplates(): Map<Column, TemplateRef<unknown>> {
    return this.templates;
  }

  public setTemplates(templates: Map<Column, TemplateRef<unknown>>): void {
    this.templates = templates;
  }
}
