<div class="container">
  <div class="row">
    <div class="col-md screen">
      <div class="row">
        <div id="uploadContainer" class="col-md">
          <label class="title">
            {{ "SCREENS.EMPLOYEES.UPLOAD.title" | translate }}
            <span *ngIf="getState() === 'existing'" class="subtitle">
              {{ "SCREENS.EMPLOYEES.UPLOAD.subtitle_existing" | translate }}
            </span>
            <span *ngIf="getState() === 'add'" class="subtitle">
              {{ "SCREENS.EMPLOYEES.UPLOAD.subtitle_add" | translate }}
            </span>
          </label>
          <div id="inputContainer">
            <div class="card p-4 my-3" style="background: rgb(248, 248, 248); border: none">
              <h5>Instructies</h5>
              <p><b>Optie 1: </b></p>
              <div class="instructionsContainer">
                <ng-container>
                  <ul class="regularList">
                    <li>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.instruction_manual_1" | translate }}</li>
                    <li>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.instruction_manual_2" | translate }}</li>
                  </ul>
                </ng-container>
                <p class="instructionBlock">
                  <span>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.example_manual_1" | translate }}</span><br />
                  <span>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.example_manual_3" | translate }}</span><br />
                  <span>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.example_manual_2" | translate }}</span><br />
                  <span>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.example_manual_4" | translate }}</span><br />
                </p>
              </div>
              <p><b>Optie 2: </b></p>
              <div class="instructionsContainer">
                <ng-container>
                  <ul class="regularList">
                    <li>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.instruction_csv_1" | translate }}</li>
                    <li>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.instruction_csv_2" | translate }}</li>
                    <li>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.instruction_csv_3" | translate }}</li>
                    <li>{{ "SCREENS.EMPLOYEES.UPLOAD.INSTRUCTIONS.instruction_csv_4" | translate }}</li>
                  </ul>
                  <br />
                </ng-container>
              </div>
            </div>
            <app-csv-block (csvEventEmitter)="setCsvUsers($event)"></app-csv-block>
          </div>

          <div class="actionsContainer">
            <ul>
              <li *ngIf="getState() === 'existing'" [ngTemplateOutlet]="csvButton"
                [ngTemplateOutletContext]="{ data: { label: 'retrieve', dialog: confirmRetrieve } }"></li>
              <li *ngIf="getState() === 'add'" [ngTemplateOutlet]="csvButton"
                [ngTemplateOutletContext]="{ data: { label: 'send', dialog: confirmNew } }"></li>

              <!-- <ng-container *ngIf="getSession().hasRole(2)">
                <li [ngTemplateOutlet]="csvButton"
                  [ngTemplateOutletContext]="{ data: { label: 'create', dialog: confirmCreate } }"></li>
                <li [ngTemplateOutlet]="csvButton"
                  [ngTemplateOutletContext]="{ data: { label: 'invite', dialog: confirmInvite } }"></li>
              </ng-container> -->

            </ul>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="getProgress()">
        <ng-container *ngIf="getProgress() === 100; else emailsLoading">
          <div id="codesContainer" class="col-md">
            <label class="title small">{{ "SCREENS.EMPLOYEES.CODES.title" | translate }}</label>
            <div id="tableContainer">
              <app-table #table [id]="getTableId()" [search]="true" [rows]="getTableData()"
                [templates]="getTableTemplates()" [pagination]="true"></app-table>
            </div>
          </div>
        </ng-container>

        <ng-template #emailsLoading>
          <div id="emailsLoader">
            <mat-progress-spinner class="example-margin" color="primary" mode="determinate" [value]="getProgress()">
            </mat-progress-spinner>
            <label>{{ getProgress() | number: "1.0-0" }}%</label>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #deactivateTemplate let-i="i">
  <mat-icon
    [matTooltip]="i.data ? 'Deactiveer deze medewerker.' : 'Het is niet mogelijk om deze medewerker te deactiveren'"
    class="action deactivate" [ngClass]="{ 'is-allowed': i.data }"
    (click)="i.data && getDialog().show(deactivateDialog, i)">
    pause_circle
  </mat-icon>

  <ng-template #deactivateDialog let-data="data">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">{{ "SCREENS.EMPLOYEES.DIALOG.DEACTIVATE.title" | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <p
            [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.DEACTIVATE.body' | translate: { email: getData(data.index, 'email') }">
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
            translate }}</button>
          <button mat-stroked-button (click)="deactivate(i.index); getDialog().close()" class="full">
            <mat-icon>chevron_right</mat-icon>
            <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #activateTemplate let-i="i">
  <mat-icon [matTooltip]="i.data ? 'Activeer medewerker.' : 'Het is niet mogelijk om deze medewerker uit te nodigen'"
    class="action activate" [ngClass]="{ 'is-allowed': i.data }"
    (click)="i.data && getDialog().show(activateDialog, i)">
    play_circle
  </mat-icon>

  <ng-template #activateDialog let-data="data">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">{{ "SCREENS.EMPLOYEES.DIALOG.ACTIVATE.title" | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <p
            [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.ACTIVATE.body' | translate: { email: getData(data.index, 'email') }">
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
            translate }}</button>
          <button mat-stroked-button (click)="activate(i.index); getDialog().close()" class="full">
            <mat-icon>chevron_right</mat-icon>
            <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #resendTemplate let-i="i">
  <mat-icon
    [matTooltip]="i.data ? 'Stuur uitnodiging opnieuw' : 'Het is niet mogelijk om de uitnodiging opnieuw te versturen'"
    class="action resend" [ngClass]="{ 'is-allowed': i.data }" (click)="i.data && getDialog().show(resendDialog, i)">
    mail
  </mat-icon>

  <ng-template #resendDialog let-data="data">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">{{ "SCREENS.EMPLOYEES.DIALOG.RESEND.title" | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <p [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.RESEND.body' | translate: { email: getData(data.index, 'email') }">
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
            translate }}</button>
          <button mat-stroked-button (click)="resend(i.index); getDialog().close()" class="full">
            <mat-icon>chevron_right</mat-icon>
            <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #inviteTemplate let-i="i">
  <mat-icon [matTooltip]="i.data ? 'Nodig medewerker uit.' : 'Het is niet mogelijk om deze medewerker uit te nodigen'"
    class="action invite" [ngClass]="{ 'is-allowed': i.data }" (click)="i.data && getDialog().show(inviteDialog, i)">
    rocket_launch
  </mat-icon>

  <ng-template #inviteDialog let-data="data">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">{{ "SCREENS.EMPLOYEES.DIALOG.INVITE.title" | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <p [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.INVITE.body' | translate: { email: getData(data.index, 'email') }">
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
            translate }}</button>
          <button mat-stroked-button (click)="invite(i.index); getDialog().close()" class="full">
            <mat-icon>chevron_right</mat-icon>
            <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #upgradeTemplate let-i="i">
  <mat-icon *ngIf="getSession().hasRole(4)"
    [matTooltip]="i.data ? 'Verhoog saldo' : 'Het is niet mogelijk om saldo te verhogen'" class="action upgrade"
    [ngClass]="{ 'is-allowed': i.data }" (click)="i.data && getDialog().show(upgradeDialog, i)">paid</mat-icon>

  <ng-template #upgradeDialog let-data="data">
    <div class="container">
      <div class="row">
        <div class="col-md">
          <span class="title">{{ "SCREENS.EMPLOYEES.DIALOG.UPGRADE.title" | translate }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <hr />
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <form>
            <mat-form-field appearance="outline">
              <mat-label>{{ "SCREENS.EMPLOYEES.DIALOG.UPGRADE.label" | translate }}</mat-label>
              <input #money matInput placeholder="0.00" />
            </mat-form-field>
          </form>
          <p
            [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.UPGRADE.body' | translate: { email: getData(data.index, 'email'), value: money.value || 0 }">
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md">
          <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
            translate }}</button>
          <button mat-stroked-button (click)="upgrade(i.index, money.value); getDialog().close()" class="full">
            <mat-icon>chevron_right</mat-icon>
            <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #confirmRetrieve let-data="data">
  <div class="container">
    <div class="row">
      <div class="col-md">
        <p [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.CONFIRM.retrieve' | translate: { amount: data.amount }"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
          translate }}</button>
        <button mat-stroked-button (click)="fillTable(data.data); getDialog().close()" class="full">
          <mat-icon>chevron_right</mat-icon>
          <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmNew let-data="data">
  <div class="container">
    <div class="row">
      <div class="col-md">
        <p [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.CONFIRM.new' | translate: { amount: data.amount }"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
          translate }}</button>
        <button mat-stroked-button (click)="inviteMassEmployee(data.data); getDialog().close()" class="full">
          <mat-icon>chevron_right</mat-icon>
          <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmCreate let-data="data">
  <div class="container">
    <div class="row">
      <div class="col-md">
        <p [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.CONFIRM.create' | translate: { amount: data.amount }"></p>
      </div>
    </div>
    <div class="row">
      <div class="col-md">
        <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
          translate }}</button>
        <button mat-stroked-button (click)="createMass(data.data); getDialog().close()" class="full">
          <mat-icon>chevron_right</mat-icon>
          <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmInvite let-data="data">
  <div class="container">
    <div class="row">
      <div class="col-md">
        <p [innerHTML]="'SCREENS.EMPLOYEES.DIALOG.CONFIRM.invite' | translate: { amount: data.amount }"></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md">
        <button mat-stroked-button (click)="getDialog().close()">{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.cancel" |
          translate }}</button>
        <button mat-stroked-button (click)="inviteMassUser(data.data); getDialog().close()" class="full">
          <mat-icon>chevron_right</mat-icon>
          <span>{{ "SCREENS.EMPLOYEES.DIALOG.BUTTONS.continue" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #csvButton let-data="data">
  <button mat-stroked-button class="full" [disabled]="!csv.value.length"
    [ngClass]="{ 'not-active': !csv.value.length }"
    (click)="getDialog().show(data.dialog, { data: csv.value, amount: csv.value.split('\n').length })">
    <mat-icon>chevron_right</mat-icon>
    <span>{{ "SCREENS.EMPLOYEES.UPLOAD.BUTTONS." + data.label | translate }}</span>
  </button>
</ng-template> -->
<ng-template #csvButton let-data="data">
  <button mat-stroked-button class="full" [disabled]="!csv.length" [ngClass]="{ 'not-active': !csv.length }"
    (click)="getDialog().show(data.dialog, { data: csv, amount: csv.split('\n').length })">
    <mat-icon>chevron_right</mat-icon>
    <span>{{ "SCREENS.EMPLOYEES.UPLOAD.BUTTONS." + data.label | translate }}</span>
  </button>
</ng-template>