import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ApiService } from "./api.service";

@Injectable()
export class MicrosoftService {
  private api: ApiService;

  public constructor(api: ApiService) {
    this.api = api;
  }

  /**
   * Create user account
   * @param email
   * @param name
   * @returns
   */
  public async createUser(email: string, name: string): Promise<boolean> {
    const api = this.getApi().get("default");

    try {
      const res = await firstValueFrom(
        api.post("account/create", {
          email: email,
          name: name,
        })
      );
      return !!res["data"].success;
    } catch (err) {
      console.error(`[USER][${email}] Unable to create user`, {
        error: err,
      });
      return false;
    }
  }

  /**
   * Invite user
   * @param email
   * @param name
   * @returns
   */
  public async inviteUser(email: string, name: string): Promise<boolean> {
    const api = this.getApi().get("default");

    try {
      const res = await firstValueFrom(
        api.post("account/invite", {
          email: email,
          name: name,
        })
      );
      return !!res["data"].success;
    } catch (err) {
      console.error(`[USER][${email}] Unable to invite user`, {
        error: err,
      });
      return false;
    }
  }

  /**
   * Getters & Setters
   */

  public getApi(): ApiService {
    return this.api;
  }

  public setApi(api: ApiService): void {
    this.api = api;
  }
}
