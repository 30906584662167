import { Component } from "@angular/core";

@Component({
  selector: "app-financial",
  templateUrl: "./financial.component.html",
  styleUrls: ["./financial.component.less"],
})
export class FinancialComponent {
  public constructor() {
    console.log("Financial");
  }

  /*
   * Getters & Setters
   */
}
