import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.less"]
})
export class UsersComponent {
  // export class UsersComponent implements OnInit {
  private route: ActivatedRoute;
  private state: string;
  public csv: string;

  constructor(route: ActivatedRoute) {
    this.route = route;
  }

  ngOnInit(): void {
    this.setState(this.route.snapshot.paramMap.get("state"));
  }


  public setCsvUsers(users) {
    this.csv = users;
  }

  public getState(): string {
    return this.state;
  }

  public setState(state: string): void {
    this.state = state;
  }

}
